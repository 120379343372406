body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
    font-family: 'Manrope-Regular';
    src: local('Manrope-Regular'), url('./assets/fonts/Manrope-Regular.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'Manrope-SemiBold';
    src: local('Manrope-SemiBold'), url('./assets/fonts/Manrope-SemiBold.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'Tinos-Regular';
    src: local('Tinos-Regular'), url('./assets/fonts/Tinos-Regular.ttf') format('truetype');
    font-weight: normal;
}

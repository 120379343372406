.leaflet-container {
    width: 100%;
    height: 450px;
    z-index: 1;
}

@media screen and (max-width: 768px) {
    .leaflet-container {
        width: 100%;
        height: 350px;
    }
}

@media screen and (max-width: 581px) {
    .leaflet-container {
        width: 101%;
        height: 150px;
    }
}
